@import url("../vendor/@fortawesome/fontawesome-free/css/all.min.css");
@import url("../vendor/@icon/dripicons/dripicons.css");
@import url("../vendor/remixicon/fonts/remixicon.css");
@import url("../vendor/line-awesome/dist/line-awesome/css/line-awesome.min.css");

img{
    max-width: 100%;
}

.border-back {
    border: 1px solid rgb(180, 172, 172);
    overflow: hidden;
}

.text-center {
    text-align: center;
}

.payment-zip-code{
    width: 100%;
    font-size: 16px;
    font-weight: 500;
}

.payment-zip-code::placeholder{
    color: rgb(157, 156, 156)  !important;
}

.form-control,
.form-select{
    padding: 10px;
    border: 1px solid #989898;
}

.form-label {
    font-weight: 600 !important;
}

button{
    /* transition-duration: 5s; */
    transition: all 1s !important;
}

.loadding-spinner{
    width: 1.5rem;
    height: 1.5rem;
}

/* toster style */
.toster-position{
    position: fixed;
    bottom: 40px; 
    left: 40% !important;
    overflow: hidden;
}


/* left side logo saml and big */
.smal-logo {
    display: none;
}

.iq-sidebar-logo img {
    height: 32px !important;
    width: 95%;
}

@media screen and (min-width: 1300px) {
    .iq-sidebar-logo img {
        height: 34px !important;
        width: 100%;
    }

    .big-logo {
        display: block;
    }

    .sidebar-main .smal-logo {
        display: block;
    }

    .sidebar-main .big-logo {
        display: none;
    }

    .side-menu-bt-sidebar-1 {
        display: none !important;
    }
}

@media screen and (max-width: 510px) {
    .modal-product-search .form-control{
        width: 170px;
    }
}